<template>
  <div v-if="caseObj" class="edit-form">
    <h4>Case</h4>
    <form>
      <div class="form-group">
        <label for="Name">Navn</label>
        <input
          type="text"
          class="form-control"
          id="Name"
          v-model="caseObj.Name"
        />
      </div>
      <div class="form-group">
        <label for="PackingDate">Pakka Dato</label>
        <input
          type="date"
          class="form-control"
          id="PackingDate"
          v-model="caseObj.PackingDate"
        />
      </div>

      <div class="form-group">
        <label for="SentDate">Sent Dato</label>
        <input
          type="date"
          class="form-control"
          id="SentDate"
          v-model="caseObj.SentDate"
        />
      </div>
      <div class="form-group">
        <label for="customer">Kundi</label>
        <select
          v-model="caseObj.CustomerId"
          id="customer"
          class="form-select form-control"
          aria-label="Default select example"
          required
        >
          <option selected>Open this select menu</option>
          <option v-for="(item, key) in customers" :key="key" :value="item.Id">
            {{ item.Name }}
          </option>
        </select>
      </div>

     
    </form>

    <button class="btn btn-danger mr-2" @click="deleteCase">
      Sletta
    </button>

    <button type="submit" class="btn btn-success" @click="updateCase">
      Goym
    </button>
    <p>{{ message }}</p>
  </div>

  <!-- <div v-else>
    <br />
    <p>Vinarliga trýst á kuffert...</p>
  </div> -->
</template>

<script>
import CaseDataService from "../../services/CaseDataService";
import CustomerDataService from "../../services/CustomerDataService";

import VueMultiselect from "vue-multiselect";
import ItemDataService from "../../services/ItemDataService";

export default {
  name: "case",
  components: { VueMultiselect },
  

  data() {
    return {
      multiValue: null,
      selected: null,
      items: [],

      caseObj: {
        Id: null,
        Name: "",
        CustomerId: null,
        PackingDate: null,
        SentDate: null,
        items: [],
      },

      message: "",
      customers: [],
    };
  },
  methods: {
    getCase(Id) {
      CaseDataService.get(Id)
        .then((response) => {
          this.caseObj = response.data;
          this.caseObj.PackingDate = new Date(response.data.PackingDate)
            .toISOString()
            .substring(0, 10);
          this.caseObj.SentDate = new Date(response.data.SentDate)
            .toISOString()
            .substring(0, 10);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveCaseDetails(id) {
      CaseDataService.getDetails(id)
        .then((response) => {
          this.caseObj.items = response.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updatePublished(status) {
      var data = {
        Id: this.caseObj.Id,
        Name: this.caseObj.Name,
        CustomerId: this.caseObj.CustomerId,
        PackingDate: this.caseObj.PackingDate,
        SentDate: this.caseObj.SentDate,
        itemsList: this.caseObj.items.map((item) => item.Id).join(","),
      };

      CaseDataService.update(this.caseObj.Id, data)
        .then((response) => {
          console.log(response.data);
          this.caseObj.published = status;
          this.message = "The status was updated successfully!";
        })
        .catch((e) => {
          console.log(e);
        });
    },

    updateCase() {
      CaseDataService.update(this.caseObj.Id, this.caseObj)
        .then((response) => {
          console.log(response.data);
          this.$router.push({ name: "cases" });
        })
        .catch((e) => {
          console.log(e);
        });
    },

    deleteCase() {
      CaseDataService.delete(this.caseObj.Id)
        .then((response) => {
          console.log(response.data);
          this.$router.push({ name: "cases" });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveCustomers() {
      CustomerDataService.getAll()
        .then((response) => {
          this.customers = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    retrieveItems() {
      ItemDataService.getAll()
        .then((response) => {
          this.items = response.data;
          console.log(response.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.retrieveItems();
    this.retrieveCustomers();
    this.message = "";
    this.getCase(this.$route.params.id);

    this.retrieveCaseDetails(this.$route.params.id);
  },
};
</script>

<style>
.edit-form {
  max-width: 300px;
  margin: auto;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
